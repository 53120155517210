<template>
    <div>
        <div class='flex items-end mt-6 mb-6'>
            <div>
                <CustomTitle class='m-0'>
                    {{ $t('ttl-organization-connect') }}
                </CustomTitle>
            </div>
        </div>
        <div class='flex mt-6 mb-6' v-if='consents.length'>
            <p class='text-gray-500'>
                {{ $t("ttl-organization-delivery-info-1") }} <span class='font-bold'>{{ organization.enterpriseName }}</span>.
            </p>
        </div>
        <ContentBox v-if='!consents.length && !loading'>
            <Translated>
                <template #en>
                    <h3> What is CodaBox Connect?</h3>
                    <p> CodaBox Connect is one of the methods that allows your accounting software to receive your files, similar to FTP transfers.</p>
                    <p><b> You are currently not using CodaBox Connect.</b></p>
                    <p>
                        For more information,
                        <a href='https://ibanity.com/products/codabox-connect' target='_blank'>click here</a>.
                    </p>
                </template>
                <template #fr>
                    <h3> Qu'est-ce que CodaBox Connect ?</h3>
                    <p> CodaBox Connect est l'une des méthodes qui permet à vos logiciels de comptabilité de recevoir vos fichiers, au même titre que les transferts FTP.</p>
                    <p><b> Pour l'instant, vous n'utilisez pas CodaBox Connect.</b></p>
                    <p>
                        Pour plus d'informations,
                        <a href='https://ibanity.com/products/codabox-connect' target='_blank'>cliquez ici</a>.
                    </p>
                </template>
                <template #nl>
                    <h3> Wat is CodaBox Connect?</h3>
                    <p> CodaBox Connect is één van de methoden waarmee je boekhoudsoftware jouw bestanden kan ontvangen, vergelijkbaar met de FTP leveringsmethode.</p>
                    <p> <b> Momenteel maak je geen gebruik van CodaBox Connect. </b></p>
                    <p>
                        Voor meer informatie,
                        <a href='https://ibanity.com/products/codabox-connect' target='_blank'>klik hier</a>.
                    </p>
                </template>
            </Translated>
        </ContentBox>

        <ContentBox v-if='consents.length || loading'>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('col-organization-consents-created') }}</ListColumn>
                    <ListColumn>{{ $t('col-organization-consents-software') }}</ListColumn>
                    <ListColumn>{{ $t('col-organization-consents-state') }}</ListColumn>
                    <ListColumn>
                        {{ $t('col-organization-consents-uuid') }}
                        <Tooltip right>
                            <template #trigger>
                                <i class='text-grey-300 fa fa-info-circle ml-1 m-center'></i>
                            </template>
                            <template #content>
                                <p>
                                    {{ $t('p-info-tooltip-consent-uuid_1') }}
                                </p>
                                <p>
                                    {{ $t('p-info-tooltip-consent-uuid') }}
                                </p>
                            </template>
                        </Tooltip>
                    </ListColumn>
                    <ListColumn>{{ $t('col-organization-consents-actions') }}</ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(consent, i) in consents' :key='i'>
                        <ListItem>
                            {{ new Date(consent.created).toLocaleString(language, {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                            }) }}
                        </ListItem>
                        <ListItem>
                            {{ consent.softwareName }}
                        </ListItem>
                        <ListItem>
                            <StatePill :label='$t(`lbl-organization-consents-state-${consent.state}`)' :background-class='consentStatusBackgroundClass(consent.state)' inline />
                        </ListItem>
                        <ListItem>
                            <button
                                class='btn btn-sm btn-default px-2 '
                                @click.prevent='copyToClipboard(consent.id)'
                            >
                                <span class='font-mono uuid align-middle'> {{ consent.id }}</span>
                                <i class='fa fa-clipboard ml-1'></i>
                            </button>
                        </ListItem>
                        <ListItem>
                            <FriendlyButton
                                v-if='consent.state == "unconfirmed"'
                                symbol='gavel'
                                extra-small
                                no-margin
                                square
                                label='lbl-manage-consent'
                                :action='() => openConsentConfirmPopup(consent)'
                            />
                            <FriendlyButton
                                v-if='consent.state == "confirmed"'
                                symbol='ban'
                                extra-small
                                no-margin
                                square
                                label='lbl-cancel-consent'
                                :action='() => openConsentCancelPopup(consent)'
                            />
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>

        <Popup :show='showConsentConfirmPopup' :close='closeConsentConfirmPopup'>
            <ConnectApiConsentConfirm v-if='consentConfirmId' :consent-id='consentConfirmId' :on-done='closeConsentConfirmPopupAndRefresh' :on-error='closeConsentConfirmPopupAndRefresh' />
        </Popup>
        <Popup :show='showConsentCancelPopup' :close='closeConsentCancelPopup'>
            <template #title>
                {{ $t('ttl-popup-remove-organization-user') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            Cancelling consent <span class='font-mono'>{{ consentCancel.id }}</span> will prevent any further
                            receipt of files of any type for organization <b>{{ consentCancel.accountantName }}</b>
                            by <b>{{ consentCancel.softwareName }}</b>, until an alternative delivery method is activated.
                        </template>
                        <template #fr>
                            L'annulation du consentement <span class='font-mono'>{{ consentCancel.id }}</span> empêchera toute
                            réception ultérieure de fichiers de tout type pour l'organisation <b>{{ consentCancel.accountantName }}</b>
                            par <b>{{ consentCancel.softwareName }}</b>, jusqu'à ce qu'une autre méthode de livraison soit activée.
                        </template>
                        <template #nl>
                            Het annuleren van toestemming <span class='font-mono'>{{ consentCancel.id }}</span> voorkomt verdere
                            ontvangst van bestanden van welk type dan ook voor de organisatie <b>{{ consentCancel.accountantName }}</b>
                            met <b>{{ consentCancel.softwareName }}</b>, totdat er een alternatieve aflevermethode is geactiveerd.
                        </template>
                    </Translated>
                </p>
            </div>
            <template #buttons>
                <FriendlyButton
                    label='lbl-popup-cancel-cancel-consent'
                    :action='closeConsentCancelPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-do-cancel-consent'
                    :action='submitConsentCancelPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>
    </div>
</template>

<script>
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ContentBox from '@/components/ContentBox';
    import Popup from '@/clientcomponents/Popup.vue';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import { gql } from '@apollo/client/core';
    import StatePill from '@/components/StatePill.vue';
    import Translated from '@/components/Translated';
    import Tooltip from '@/components/Tooltip';
    import ConnectApiConsentConfirm from '@/fiduciary/ConnectApiConsentConfirm.vue';
    import { simpleLocale } from '@/i18n';

    export default {
        name: 'OrganizationUsersView',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            Translated,
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            ContentBox,
            Popup,
            CustomTitle,
            StatePill,
            Tooltip,
            ConnectApiConsentConfirm,
        },
        data () {
            return {
                loading: false,
                consents: [],
                showConsentCancelPopup: false,
                showConsentConfirmPopup: false,
                consentConfirmId: null,
            };
        },
        computed: {
            language () {
                return simpleLocale(this.$i18n.locale);
            },
        },
        mounted () {
            this.fetchList();
        },
        methods: {
            async fetchList () {
                this.loading = true;
                this.consents = await this.getConsents();
                this.loading = false;
            },
            async fetchListNoSpinner () {
                this.consents = await this.getConsents();
            },
            async getConsents () {
                try {
                    const results = await this.$apollo.query({
                        query: gql`query Consents($accountantId: String) {
                            accountingSoftwareConsents(accountantId: $accountantId) {
                                id
                                userId
                                softwareName
                                accountantId
                                accountantName
                                confirmationUri
                                state
                                created
                                modified
                                redirectUri
                                softwareId
                                accountantCompanyNumber
                            },
                        }`,
                        variables: {
                            accountantId: this.organization.id,
                        },
                    });
                    return results.data.accountingSoftwareConsents;
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                } finally {
                    this.loading = false;
                }
            },
            openConsentCancelPopup (consent) {
                this.consentCancel = consent;
                this.showConsentCancelPopup = true;
            },
            closeConsentCancelPopup () {
                this.consentCancel = null;
                this.showConsentCancelPopup = false;
            },
            openConsentConfirmPopup (consent) {
                this.consentConfirmId = consent.id;
                this.showConsentConfirmPopup = true;
            },
            closeConsentConfirmPopup () {
                this.consentConfirmId = null;
                this.showConsentConfirmPopup = false;
            },
            async closeConsentConfirmPopupAndRefresh () {
                this.closeConsentConfirmPopup();
                await this.fetchList();
            },
            async submitConsentCancelPopup () {
                try {
                    const actionResult = await this.$apollo.query({
                        query: gql`mutation CancelConsent($consentId: String) {
                            cancelAccountingSoftwareConsent(consentId: $consentId) {
                                errors {
                                    code
                                    detail
                                }
                            }
                        }`,
                        variables: {
                            consentId: this.consentCancel.id,
                        },
                    });
                    const errors = actionResult.data.cancelAccountingSoftwareConsent.errors;
                    if (errors && errors.length > 0) {
                        notify.error(this.$t('err-cancel-consent'));
                    } else {
                        notify.success(this.$t('succ-cancel-consent'));
                    }
                } catch {
                    notify.error(this.$t('err-cancel-consent'));
                }

                await this.fetchList();

                this.closeConsentCancelPopup();
            },
            consentStatusBackgroundClass (state) {
                switch (state) {
                    case 'confirmed':
                        return 'bg-green-300';
                    case 'unconfirmed':
                        return 'bg-orange-300';
                    case 'cancelled':
                        return 'bg-grey-300';
                    case 'denied':
                        return 'bg-red-300';
                    default:
                        return 'bg-blue-300';
                }
            },
            async copyToClipboard (uuid) {
                try {
                    await navigator.clipboard.writeText(uuid);
                    notify.success(this.$t('p-uuid-copied-clipboard-success'));
                } catch (err) {
                    notify.error(this.$t('p-uuid-copied-clipboard-error'));
                }
            },
        },
    };
</script>

<style scoped>
.uuid {
    display: inline-block;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
