<script>
    import { gql } from '@apollo/client/core';
    import ArchiveButton from '../components/ArchiveButton';
    import ArchiveModal from '../components/SodaArchiveWarningModal';
    import ClientAddSoda from '../components/ClientAddSoda';
    import SodaState from '../components/Sodastate';
    import Loader from '@/loader';
    import notify from '@/notify';
    import validate from '@/validate';
    import utils from '@/utils';
    import Tooltip from '@/components/Tooltip.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import SendSodaCodaByMail from './components/SendSodaCodaByMail';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import { dateFormat } from '@/filters';
    import Popup from '@/clientcomponents/Popup.vue';
    import { Form } from 'vee-validate';

    const defaultDetSodaMandatesParams = {
        loading: true,
    };

    export default {
        name: 'ClientSoda',
        props: {
            clientV2: { type: Object, required: true },
            currentFiduciary: { type: Object, required: true },
        },
        components: {
            Popup,
            SodaState,
            ArchiveButton,
            ArchiveModal,
            ClientAddSoda,
            Tooltip,
            ContentBox,
            SendSodaCodaByMail,
            FriendlyButton,
            // eslint-disable-next-line vue/no-reserved-component-names
            Form,
        },
        data () {
            return {
                sodaMandates: [],
                archival: {
                    showWarning: false,
                    confirm: { confirmed: false },
                    mandateId: null,
                },
                newMandateModal: false,
                saving: false,
            };
        },
        beforeMount () {
            this.getSodaMandates();
        },
        mounted () {
            this.document = 'soda';
        },
        emits: ['clientupdated'],
        methods: {
            dateFormat: dateFormat,
            async submitOrderSodaMandateForm (values) {
                if (this.saving) {
                    return;
                }

                Loader.start();
                this.saving = true;

                // Reformat for backend
                let socialOfficesData = [];
                if (values.socialOffices && values.socialOffices.length > 0) {
                    values.socialOffices.forEach(so => {
                        if (so) {
                            socialOfficesData.push({
                                'id': so,
                            });
                        }
                    });
                }

                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation OrderSodaMandate($input: SodaOrderingInput!) {
                                orderSodaMandate(input: $input) {
                                    errors { code, detail, source { pointer } }
                                }
                            }`,
                        variables: {
                            input: {
                                fiduciaryId: this.currentFiduciary.id,
                                clientId: this.clientV2.id,
                                socialOffices: socialOfficesData,
                            },
                        },
                    });

                    if (!data.orderSodaMandate.errors) {
                        notify.success(this.$t('p-order-soda-success'));
                        await this.getSodaMandates();
                        this.closeNewMandateModal();
                    } else {
                        let errors = data.orderSodaMandate.errors;
                        for (let i = 0; i < errors.length; i++) {
                            let error = errors[i];
                            if (error && error.source && error.source.pointer.match(/^\/data\/socialOffices\/\d+\/id$/) && this.$refs.socialOfficesInputs) {
                                let inputIndex = error.source.pointer.substring(20, 21);

                                let errorMessage = this.$t(error.code);

                                // Duplicate has no specific error code, check on detail of the error:
                                if (error.detail.match(/^social office .* is duplicated$/)) {
                                    errorMessage = this.$t('val-unique-office');
                                }

                                this.$refs.orderSodaMandateForm.setErrors({
                                    [`socialOffices[${inputIndex}]`]: errorMessage,
                                });

                                delete data.orderSodaMandate.errors[i];
                            }
                        }
                        if (utils.count(data.orderSodaMandate.errors)) {
                            await validate.notifyErrors(data.orderSodaMandate.errors);
                        }
                    }

                    Loader.stop();
                    this.saving = false;
                } catch (err) {
                    Loader.stop();
                    this.saving = false;
                    notify.error(this.$t('err-unknown'));
                }
            },
            async getSodaMandates (params = defaultDetSodaMandatesParams) {
                Loader.start();
                this.loading = true;
                if (params.loading) {
                    this.loading = true;
                }
                const { data } = await this.$apollo.query({
                    query: gql`query GetSodaMandates($clientId: String) {
                        sodaMandates(clientId:$clientId) {
                            id
                            clientId
                            socialOfficeId
                            socialOfficeName
                            state
                            stateDate
                            validityStartDate
                        }
                    }`,
                    variables: {
                        clientId: this.clientV2.id,
                    },
                });
                this.sodaMandates = data.sodaMandates || [];

                Loader.stop();
                this.loading = false;
            },
            async archiveSodaMandate () {
                if (!this.archival.confirm.confirmed) {
                    return;
                }

                this.archival.showWarning = false;

                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation ArchiveSodaMandate($mandateId: String) {
                            archiveSodaMandate(sodaMandateId: $mandateId) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: { mandateId: this.archival.mandateId },
                    });
                    if (data.archiveSodaMandate.errors) {
                        validate.notifyErrors(data.archiveSodaMandate);
                    } else {
                        notify.success(this.$t('p-archived-soda-success'));
                        this.getSodaMandates();
                    }
                    return;
                } catch (err) {
                    notify.error(this.$t('err-unknown'));
                    return;
                }
            },
            doArchiveWithWarning (id) {
                this.archival.mandateId = id;
                this.archival.confirm.confirmed = false;
                this.archival.showWarning = true;
            },
            cancelArchive () {
                this.archival.showWarning = false;
                this.archival.mandateId = null;
            },
            openNewMandateModal () {
                this.newMandateModal = true;
            },
            closeNewMandateModal () {
                this.newMandateModal = false;
            },
            isSubmitOrderMandateDisabled (formValues) {
                const hasSocialOfficesSelected = (
                    formValues.socialOffices &&
                    formValues.socialOffices.some(so => !!so)
                );
                return this.saving || !hasSocialOfficesSelected;
            },
        },
    };
</script>
<template>
    <div class='client-subppage'>
        <content-box :title='$t("ttl-mandates")'>
            <template #actions>
                <FriendlyButton
                    label='btn-new-soda-mandate'
                    :action='openNewMandateModal'
                    square
                    extra-small
                    no-margin
                    id='orderSodaMandateButton'
                />
            </template>
            <div v-if='sodaMandates.length <= 0'>
                <tooltip big right>
                    <template #trigger>
                        <span
                            class='py-1 px-3 rounded-full inline-block bg-grey-300 bg-opacity-20'
                        >
                            {{ $t('state-no-soda') }}
                        </span>
                    </template>
                    <template #content>
                        {{ $t('p-no-soda') }}
                    </template>
                </tooltip>
            </div>
            <ArchiveModal
                :show='archival.showWarning'
                :proceed='archiveSodaMandate'
                :confirm='archival.confirm'
                :close='cancelArchive'
                id='archiveSodaMandateModal'
            />
            <div :class='{"table-responsive": $store.state.gui === "mobile"}' v-if='sodaMandates.length > 0'>
                <table class='table' id='sodaList'>
                    <colgroup>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col class='cb-col-md'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{{ $t('th-social-office') }}</th>
                            <th>{{ $t('th-state-date') }}</th>
                            <th>{{ $t('th-state') }}</th>
                            <th>{{ $t('th-actions') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='sodaMandate in sodaMandates'>
                            <td>{{ sodaMandate.socialOfficeName }}</td>
                            <td>{{ dateFormat(sodaMandate.stateDate) }}</td>
                            <td><SodaState :mandate='sodaMandate' /></td>
                            <td><ArchiveButton :mandate='sodaMandate' :archive='doArchiveWithWarning' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </content-box>

        <SendSodaCodaByMail
            v-if='sodaMandates.length > 0'
            :client-v2='clientV2'
            @clientupdated='$emit("clientupdated")'
        />

        <Form @submit='submitOrderSodaMandateForm' ref='orderSodaMandateForm' v-slot='{ values }'>
            <Popup
                :show='newMandateModal'
                :close='() => { newMandateModal = false }'
                id='orderSodaMandateModal'
            >
                <template #title>
                    {{ $t('ttl-new-soda-mandate') }}
                </template>
                <ClientAddSoda
                    :min-required='1'
                    ref='socialOfficesInputs'
                    :form-ref='$refs.orderSodaMandateForm'
                />
                <template #buttons>
                    <div class='flex'>
                        <FriendlyButton
                            label='btn-cancel'
                            :action='closeNewMandateModal'
                            square
                            extra-small
                            no-margin
                            secondary
                            class='mr-2'
                        />
                        <FriendlyButton
                            label='btn-save-mandate'
                            :disabled='isSubmitOrderMandateDisabled(values)'
                            square
                            extra-small
                            no-margin
                            type='submit'
                            id='confirmOrderSodaMandateButton'
                        />
                    </div>
                </template>
            </Popup>
        </Form>
    </div>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }
</style>
