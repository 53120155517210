<script>
    export default {
        name: 'CodaTermsNl',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>CodaBox bezorgt je rechtstreeks de elektronische bankkuittreksels in CODA-formaat, voor meerdere Belgische financiële instellingen. Zo hoeft je klant je deze rekeninginformatie niet meer te bezorgen, en heeft je kantoor sneller toegang tot de financiële gegevens voor de boekhouding.</p>

        <p>Via MyCodaBox kan je CODA bestellen voor je klanten, wij nemen dan het verdere administratieve werk op: het opmaken van de correcte bankmandaten, deze bezorgen aan je klant voor ondertekening, de opvolging met de banken, … . Jij kan de status van de aanvragen opvolgen via MyCodaBox. ia MyCodaBox.</p>

        <p>CodaBox ontvangt het eerste CODA-bestand zodra de bank het mandaat heeft gevalideerd en geactiveerd en er een eerste transactie heeft plaatsgevonden op de rekening. Vervolgens controleren wij de nummering, de versie en het saldo, maken we een pdf-bestand op met de CODA-gegevens en bzorgen je dagelijks de CODA-bestanden in een formaat en structuur die compatibel zijn met uw boekhoudprogramma.</p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Volmachten: 24 € per volmacht</p>

        <p>Optionele dienst - Levering van CODA-bestanden aan je klant via SFTP: 100 € per SFTP-server (<a href='https://faq.codabox.com/nl/support/solutions/articles/75000087150-coda-ook-geleverd-aan-je-klant#link' target='_blank'>meer info</a>)</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 zichtrekeningen</td>
                    <td>3,62 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 241 en 500 zichtrekeningen</td>
                    <td>4,53 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 121 en 240 zichtrekeningen</td>
                    <td>5,01 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 61 en 120 zichtrekeningen</td>
                    <td>6,28 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Tussen 3 en 60 zichtrekeningen</td>
                    <td>7,53 €/maand/rekening</td>
                </tr>
                <tr>
                    <td>Voor 1 en 2 zichtrekeningen</td>
                    <td>9,37 €/maand/rekening</td>
                </tr>
            </tbody>
        </table>
        <p>
            Voor de bepaling van het correcte tarief wordt ook rekening gehouden met het aantal actieve diensten voor CARO en VOILA. Meer details kan je vinden in de Algemene Voorwaarden, via de link hieronder.
        </p>

        <h3>Algemene voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CODA (B1-C1-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
