<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import {Auth} from '@aws-amplify/auth';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
import CustomTitle from '@/components/Title';
import Translated from '@/components/Translated';
import { Form } from 'vee-validate';

export default {
    name: 'ForgotPassword',
    components: {
        NavBar,
        FriendlyButton,
        FriendlyInput,
        CustomTitle,
        Translated,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            cognitoError: '',
            success: false,
        };
    },
    computed: {
        initialEmail () {
            return this.$route.query.email ? this.$route.query.email : '';
        },
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        async forgotPassword (values) {
            try {
                await Auth.forgotPassword(values.email, {lang: this.$i18n.locale});
                this.success = true;
            } catch (e) {
                this.cognitoError = this.$t('err-unknown-retry-later');
            }
        },
    },
};

</script>

<template>
    <div class='forgot-password'>
        <NavBar no-nav transparent />
        <div id='forgot-password-form' class='forgot-password__panel'>
            <div class='w-full mb-12 flex justify-center'>
                <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
            </div>
            <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                <Form
                    ref='forgotPasswordForm'
                    tag='div'
                    @submit='forgotPassword'
                    v-slot='{ values, errors }'
                    v-if='!success'
                >
                    <CustomTitle class='m-0' :style-of='3'>
                        {{ $t('h-forgot-password') }}
                    </CustomTitle>

                    <p class='mt-6 text-grey-400'>
                        {{ $t('forgot-password-text') }}
                    </p>

                    <FriendlyInput
                        autocomplete='email'
                        label='lbl-email'
                        :value='initialEmail'
                        name='email'
                        fullwidth
                        :placeholder='$t("placeholder-email")'
                        class='mt-3'
                        rules='email|required'
                        text-size='xl'
                        ref='emailInput'
                        mode='passive'
                    />
                    <div v-if='values.email && values.email.length > 0 && "email" in errors' class='text-red-300 ml-2 inline-block font-bold text-sm transition-all mt-1'>
                        <translated>
                            <template #en>
                                Please try again or contact us via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> in case you still need to migrate your MyCodaBox account.
                            </template>
                            <template #nl>
                                Probeer het opnieuw of neem contact op met ons op via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> als je jouw MyCodaBox account nog moet migreren.
                            </template>
                            <template #fr>
                                Veuillez réessayer ou contacter <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> au cas où vous auriez encore besoin de migrer votre compte MyCodaBox.
                            </template>
                        </translated>
                    </div>
                    <div class='mt-6 mb-3'>
                        <FriendlyButton
                            label='btn-forgot-password'
                            no-margin
                            fullwidth
                            square
                            small
                            type='submit'
                            :disabled='!values.email || values.email.length === 0 || "email" in errors'
                        />
                    </div>
                </Form>
                <div v-else>
                    <CustomTitle class='m-0 mb-6' :style-of='3'>
                        {{ $t('h-forgot-password-success') }}
                    </CustomTitle>
                    <translated>
                        <template #en>
                            <p class='font-bold'>
                                You will receive an email to reset your password if we find your login details in our system.
                            </p>
                            <p>
                                Be sure to check your spam folder too.<br>
                                Didn't receive an email? Contact us at <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                            </p>
                        </template>
                        <template #nl>
                            <p class='font-bold'>
                                Je ontvangt een e-mail van ons om je wachtwoord opnieuw in te stellen als we je logingegevens terugvinden in ons systeem.
                            </p>
                            <p>
                                Controleer zeker ook je spamfolder.<br>
                                Geen e-mail ontvangen? Contacteer ons via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                            </p>
                        </template>
                        <template #fr>
                            <p class='font-bold'>
                                Si cet email est lié à un compte utilisateur, un e-mail vous a été envoyé pour réinitialiser votre mot de passe.
                            </p>
                            <p>
                                N'oubliez pas de vérifier vos courriers indésirables.<br>
                                Vous n'avez pas reçu d'e-mail ? Contactez-nous via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                            </p>
                        </template>
                    </translated>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.forgot-password {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.forgot-password__panel {
    @apply pb-24 my-auto;
    max-width: 430px;
    width: 90%;
}
</style>
