<script>
    export default {
        name: 'CcsTermsEnglishView',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>The CodaBox CARO service is an additional CodaBox service. Accountants who subscribe their clients to the CARO service can expect to receive their client’s professional credit card statements to be delivered directly to them for all credit card statements for which the account holder and card holder have provided their consent. The CARO files will be delivered on a monthly basis aligned with the credit card issuer, when there is at least one transaction performed with the credit card during the billing period. If there are no transactions performed with the credit card during a billing period, no credit card statements are created by the credit card processor, so neither can they be delivered by CodaBox.</p>

        <p>The CodaBox CARO files will be delivered through the existing secure CODA delivery channels.</p>

        <p>The CodaBox CARO files:</p>

        <ul>
            <li>
                Will cover Mastercard and VISA credit cards issued by the banks listed on
                <ul>
                    <li>
                        NL: <a href='https://www.codabox.com/nl/caro/' target='_blank'>https://www.codabox.com/nl/caro/</a>
                    </li>
                    <li>
                        FR: <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a>
                    </li>
                </ul>
            </li>
            <li>Will get a logical name</li>
            <li>Will be delivered in PDF and in structured data</li>
        </ul>

        <p>The CodaBox CARO service will offer you and your clients numerous advantages like: more efficiency, less errors, less missing documents,… You have certainty that you receive your client’s credit card statements directly from CodaBox and you won’t need to enter the data into your accounting software.</p>

        <p>CodaBox is launching the service with a limited set of supported banks. CodaBox is confident that the number of supported banks will grow in the near future. The current list of supported banks for the CARO service can be found on: NL : <a href='https://www.codabox.com/nl/caro/' target='_blank'>https://www.codabox.com/nl/caro/</a> or FR : <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/</a></p>

        <p>The CodaBox CARO service is not available as a separate service and will only be available for those professional clients of the accountants which have active CODA mandates for the bank.</p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>24 € per consented client reference (*)</p>

        <h4 class='cb-service-title'>
            Recurrent Costs
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 client references</td>
                    <td>3,62 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 client references</td>
                    <td>4,53 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 client references</td>
                    <td>5,01 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 client references</td>
                    <td>6,28 €/month/client reference</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 client references</td>
                    <td>7,53 €/month/client reference</td>
                </tr>
                <tr>
                    <td>For 1 and 2 client references</td>
                    <td>9,37 €/month/client reference</td>
                </tr>
            </tbody>
        </table>
        <p>To determine the correct price, the number of CODA-services and VOILA-services are also taken into account. More details can be found in the General Terms and Conditions (links below).</p>

        <p>(*)identification of the credit card statement, some bank products will allow multiple cards on 1 credit card statement (ie. Client reference).</p>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CARO (B4-C4-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CARO (B4-C4-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
