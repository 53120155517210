import { createI18n } from 'vue-i18n';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';
import nl from './locales/nl/translation.json';

function simpleLocale (locale) {
    return locale.split('_')[0].split('-')[0];
}

function fullLocale (locale) {
    return {
        'fr': 'fr_FR',
        'nl': 'nl_BE',
        'en': 'en_US',
    }[simpleLocale(locale)] || 'en_US';
}

function detectBrowserLocale () {
    return fullLocale(window.navigator.userLanguage || window.navigator.language);
}

function loadSavedLocale (def) {
    return localStorage.getItem('locale') || def || 'en_US';
}

const messages = {
    en_US: en,
    fr_FR: fr,
    nl_BE: nl,
};

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: loadSavedLocale(detectBrowserLocale()),
    fallbackLocale: 'en_US',
    messages,
});

function setLocale (locale) {
    if (locale) {
        const selectedLocale = fullLocale(locale);
        localStorage.setItem('locale', selectedLocale);
        i18n.global.locale.value = selectedLocale;
    }
}

export { i18n, loadSavedLocale, setLocale, simpleLocale, fullLocale };
