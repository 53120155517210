import { createRouter, createWebHashHistory } from 'vue-router';
import Signup from './user/SignUp.vue';
import ChangePassword from './user/ChangePassword.vue';
import ConfirmInvitation from './user/ConfirmInvitation.vue';
import MaintenancePage from './common/MaintenancePage.vue';

import EmailVerificationWizard from './client/EmailVerificationWizard.vue';
import RestartEmailVerification from './client/RestartEmailVerification.vue';

import Fiduciary from './fiduciary/Fiduciary.vue';
import Dashboard from './fiduciary/Dashboard.vue';
import CodaSearch from './fiduciary/CodaSearch.vue';
import SodaSearch from './fiduciary/SodaSearch.vue';
import ClientSearch from './fiduciary/ClientSearch.vue';
import ClientTransfer from './fiduciary/ClientTransfer.vue';
import ClientRegularTransferList from './fiduciary/ClientRegularTransferList.vue';
import ClientPlatformTransferList from './fiduciary/ClientPlatformTransferList.vue';
import Voila2List from './fiduciary/Voila2List.vue';
import FiduClient from './fiduciary/Client.vue';
import NewClient from './fiduciary/NewClient';
import ClientInfo from './fiduciary/ClientInfo';
import ClientCoda from './fiduciary/ClientCoda';
import ClientSoda from './fiduciary/ClientSoda';
import ClientVoila2 from './fiduciary/ClientVoila2.vue';
import ClientCaro from './fiduciary/ClientCaro';
import Services from './fiduciary/Services.vue';
import FiduciaryInfo from './fiduciary/FiduciaryInfo.vue';
import Archived from './fiduciary/Archived.vue';
import FiduciarySettings from './fiduciary/FiduciarySettings.vue';
import CreditCardStatementClientsList from './fiduciary/CreditCardStatementClientsList.vue';
import ConnectApiConsentPage from './fiduciary/ConnectApiConsentPage.vue';

import Reseller from './reseller/Reseller.vue';
import RslrFiduSearch from './reseller/FiduSearch.vue';
import RslrCodaSearch from './reseller/CodaSearch.vue';
import RslrSodaSearch from './reseller/SodaSearch.vue';

import Unsubscribe from './components/Unsubscribe.vue';

import OrganizationView from './organization/OrganizationView';
import OrganizationSettingsView from './organization/settings/OrganizationSettingsView';
import OrganizationUsersView from './organization/settings/OrganizationUsersView';
import OrganizationDeliveryView from './organization/settings/OrganizationDeliveryView';
import OrganizationInformations from './organization/settings/OrganizationInformations';

import store from './store';
import utils from './utils.js';

import Home from '@/views/Home';

import NotFoundView from '@/views/NotFoundView';

import { authGuard, simpleCheckSession }  from '@/auth/authGuard';
import Login from '@/common/Login.vue';
import ForgotPassword from '@/common/ForgotPassword.vue';
import ResetPassword from '@/common/ResetPassword.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        meta: {
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: simpleCheckSession,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        beforeEnter: simpleCheckSession,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        beforeEnter: simpleCheckSession,
    },
    {
        path: '/verify/:token',
        name: 'email-verification',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'accept',
        },
    },
    {
        path: '/refuse-verification/:token',
        name: 'email-verification-refuse',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'refuse',
        },
    },
    {
        path: '/verify/restart/:token',
        name: 'restart-email-verification',
        component: RestartEmailVerification,
        meta: {
            title: 'Email Verification refresh',
        },
    },
    {
        path: '/sign-up/:invitationToken',
        name: 'signup',
        component: Signup,
        meta: { title: 'Signup' },
    },
    {
        path: '/change-password',
        name: 'changePW',
        component: ChangePassword,
        meta: {
            title: 'Change Password',
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/confirm-invitation/:token',
        name: 'confirmInvitation',
        component: ConfirmInvitation,
        meta: {
            title: 'Confirm User Invitation',
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/organization/:organizationId',
        name: 'organization',
        component: OrganizationView,
        meta: {
            authGuard: {
                restricted: true,
                restrictedToEntity: 'organization',
            },
        },
        children: [
            {
                path: 'administration',
                name: 'org-administration',
                component: OrganizationSettingsView,
                children: [
                    {
                        path: '',
                        name: 'organization-administration-users',
                        component: OrganizationUsersView,
                        meta: {
                            title: 'Users',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                    {
                        path: 'connect',
                        name: 'organization-administration-connect',
                        component: OrganizationDeliveryView,
                        meta: {
                            title: 'CodaBox Connect',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                    {
                        path: 'informations',
                        name: 'organization-administration-informations',
                        component: OrganizationInformations,
                        meta: {
                            title: 'Organization Informations',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                                restrictedToOrgRole: 'admin',
                            },
                        },
                    },
                ],
                meta: {
                    title: 'Organization settings',
                    authGuard: {
                        restricted: true,
                        restrictedToEntity: 'organization',
                        restrictedToOrgRole: 'admin',
                    },
                },
            },
            {
                path: 'environment/:environmentId',
                name: 'environment',
                component: Fiduciary,
                meta: {
                    title: 'Fiduciary',
                    authGuard: {
                        restricted: true,
                        restrictedToEntity: 'organization',
                    },
                },
                children: [
                    {
                        path: '',
                        name: 'dashboard',
                        component: Dashboard,
                        meta: {
                            title: 'Fiduciary Dashboard',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'coda',
                        name: 'fidu-coda-search',
                        component: CodaSearch,
                        meta: {
                            title: 'Coda Search',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'soda',
                        name: 'fidu-soda-search',
                        component: SodaSearch,
                        meta: {
                            title: 'Soda Search',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'purchases',
                        name: 'fidu-purchase-search',
                        component: Voila2List,
                        meta: {
                            title: 'Purchase Search',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'caro',
                        name: 'fidu-ccs-clients-list',
                        component: CreditCardStatementClientsList,
                        meta: {
                            title: 'Credit card statements',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'contracts',
                        name: 'fidu-services-root',
                        component: Services,
                        meta: {
                            title: 'Contracts & Services',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'archived/:type/:uid/',
                        name: 'archived',
                        component: Archived,
                        meta: {
                            title: 'Contracts & Services',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients',
                        name: 'fidu-client-search',
                        component: ClientSearch,
                        meta: {
                            title: 'Client Search',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients/nopurchases',
                        name: 'fidu-client-search-no-purchases',
                        component: ClientSearch,
                        meta: {
                            title: 'Client Search',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'clients/transfers',
                        name: 'fidu-client-transfer-lists',
                        component: ClientTransfer,
                        meta: {
                            title: 'Client Transfer',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-regular-transfer-list',
                                component: ClientRegularTransferList,
                                meta: {
                                    title: 'Client Regular Transfer List',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'platform',
                                name: 'fidu-client-platform-transfer-list',
                                component: ClientPlatformTransferList,
                                meta: {
                                    title: 'Client Platform Transfer List',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: 'info',
                        name: 'fidu-info',
                        component: FiduciaryInfo,
                        meta: {
                            title: 'Fiduciary Info',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client',
                        name: 'fidu-client-root',
                        component: FiduClient,
                        meta: {
                            title: 'Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client/new',
                        name: 'fidu-client-new',
                        component: NewClient,
                        meta: {
                            title: 'New Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                    {
                        path: 'client/:uid/',
                        component: FiduClient,
                        name: 'client-detail-page',
                        meta: {
                            title: 'Fiduciary Client',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                        props: (route) => ({
                            routeName: route.name,
                            clientId: route.params.uid,
                        }),
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-uid-info',
                                component: ClientInfo,
                                meta: {
                                    title: 'Fiduciary Client Infos',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'coda',
                                name: 'fidu-client-uid-coda',
                                component: ClientCoda,
                                meta: {
                                    title: 'Fiduciary Client Coda',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'soda',
                                name: 'fidu-client-uid-soda',
                                component: ClientSoda,
                                meta: {
                                    title: 'Fiduciary Client Soda',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'voila',
                                name: 'fidu-client-uid-voila',
                                component: ClientVoila2,
                                meta: {
                                    title: 'Fiduciary Client VOILA',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                            {
                                path: 'caro',
                                name: 'fidu-client-uid-credit-card-statement',
                                component: ClientCaro,
                                meta: {
                                    title: 'Fiduciary Client Credit Card Statement',
                                    authGuard: {
                                        restricted: true,
                                        restrictedToEntity: 'organization',
                                    },
                                },
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        name: 'fidu-settings',
                        component: FiduciarySettings,
                        meta: {
                            title: 'Fiduciary settings',
                            authGuard: {
                                restricted: true,
                                restrictedToEntity: 'organization',
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/fiduciary/connect-api-consent',
        name: 'connect-api-consent',
        component: ConnectApiConsentPage,
        meta: {
            title: 'Connect Api consent',
            authGuard: {
                restricted: true,
            },
        },
    },
    {
        path: '/reseller/:resellerId',
        name: 'reseller',
        component: Reseller,
        meta: {
            title: 'Reseller',
            authGuard: {
                restricted: true,
                restrictedToEntity: 'reseller',
            },
        },
        children: [
            {
                path: 'fiduciaries',
                name: 'rslr-fidu-search',
                component: RslrFiduSearch,
                meta: {
                    title: 'Fiduciaries',
                    authGuard: {
                        restricted: true,
                        restrictedToEntity: 'reseller',
                    },
                },
            },
            {
                path: 'coda',
                name: 'rslr-coda-search',
                component: RslrCodaSearch,
                meta: {
                    title: 'Coda Search',
                    authGuard: {
                        restricted: true,
                        restrictedToEntity: 'reseller',
                    },
                },
            },
            {
                path: 'soda',
                name: 'rslr-soda-search',
                component: RslrSodaSearch,
                meta: {
                    title: 'Soda Search',
                    authGuard: {
                        restricted: true,
                        restrictedToEntity: 'reseller',
                    },
                },
            },
        ],
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
    },
    // todo: create 404 page
    { path: '/:pathMatch(.*)*', name: '404', component: NotFoundView },
    { path: '/maintenance', component: MaintenancePage },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    /* detect query params to enable flags */
    if (to.query.flag) {
        for (let flag of to.query.flag.split(',')) {
            store.commit('setFlag', flag);
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    /* detect maintenance mode */
    if (store.state.maintenance && to.fullPath !== '/maintenance') {
        next('/maintenance');
    } else {
        next();
    }
});

router.beforeEach(async (to, from, next) => {
    /* handle user load, check roles and access
    *
    * "restricted" in authGuard is mostly there to be explicit and avoid empty dict hen there no other restrictions
    *  */
    if (to.meta.authGuard?.restricted) {
        await authGuard(to, from, next);
    } else {
        next();
    }
});

/* -- Scrolling -- */

router.afterEach((to, from) => {
    if (!(to.name === 'fidu-client-uid-info')) {
        utils.scrollTop();
    }
});

export default router;
