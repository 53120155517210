<template>
    <li class='sidenav-group' :class='{"sidenav-group--background": background}'>
        <template v-if='title'>
            <div v-if='collapsable' @click='toggleCollapse' class='sidenav-group__head'>
                {{ title }}
                <i class='fa ml-auto text-xs opacity-50' :class='`fa-${collapseIcon}`'></i>
            </div>
            <div v-else class='px-6 pt-3 pb-3 font-bold'>
                {{ title }}
            </div>
        </template>
        <ul v-show='!collapsed' class='list-none p-0'>
            <slot></slot>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'SidenavGroup',
    props: {
        title: {
            type: String,
        },
        collapsable: Boolean,
        background: Boolean,
    },
    data () {
        return {
            collapsed: false,
        };
    },
    computed: {
        collapseIcon () {
            return this.collapsed ? 'plus' : 'minus';
        },
    },
    methods: {
        toggleCollapse () {
            if (this.collapsable) {
                this.collapsed = !this.collapsed;
            }
        },
    },
};
</script>

<style scoped>
.sidenav-group {
    @apply block rounded-lg mb-6;
    box-sizing: border-box;
}

.sidenav-group--background {
    @apply bg-grey-100 py-2;
}

.sidenav-group__head {
    @apply flex items-center cursor-pointer px-4 pt-2 pb-2 font-bold;
}
</style>
