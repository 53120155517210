<script>
    export default {
        name: 'CodaTermsFr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>CodaBox récupère et met à votre disposition les relevés bancaires électroniques ‘CODA’ de toute entreprise. L'utilisation du service CODA offre à vous et vos clients de nombreux avantages : plus d'efficacité, moins d'erreurs, moins de documents manquants et ce sans besoin d'effectuer d’encodage manuel.</p>

        <p>Via MyCodaBox vous pouvez commander le service CODA pour vos client, CodaBox gère ensuite les démarches administratives : génération des mandats bancaires, prise en charge du processus de signature des mandats par les clients, … . Vous pouvez faire le suivi des mandats dans MyCodaBox.</p>

        <p>CodaBox reçoit le premier fichier CODA lorsque la banque a validé les signatures, activé le mandat et qu’il y a eu un premier mouvement sur le compte. CodaBox vérifie la numérotation, la version et les soldes, créée un fichier PDF qui reprend les informations du CODA, et tous les jours vous transmet les fichiers CODA dans un format et une structure compatible avec votre programme comptable.</p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Tarifs administratifs
        </h4>

        <p>Procurations : 24 € par procuration</p>

        <p>Service optionnel - Livraison des fichiers CODA à votre client par SFTP : 100 € par serveur SFTP (<a href='https://faq.codabox.com/fr/support/solutions/articles/75000087150-coda-%C3%A9galement-livr%C3%A9-%C3%A0-votre-client#link' target='_blank'>plus d’infos</a>)</p>

        <h4 class='cb-service-title'>
            Tarifs récurrents
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 comptes à vue</td>
                    <td>3,62 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 comptes à vue</td>
                    <td>4,53 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 comptes à vue</td>
                    <td>5,01 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 comptes à vue</td>
                    <td>6,28 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 comptes à vue</td>
                    <td>7,53 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 comptes à vue</td>
                    <td>9,37 €/mois/compte</td>
                </tr>
            </tbody>
        </table>

        <h3>Conditions Générales</h3>

        <p><a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CODA (B1-C1-D).pdf' target='_blank'>Conditions générales du service</a></p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
