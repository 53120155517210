<script>
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Popup from '@/clientcomponents/Popup';

    export default {
        name: 'ArchiveWarningModal',
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            proceed: {
                type: Function,
                required: true,
            },
            confirm: {
                type: Object,
                required: true,
            },
            close: {
                type: Function,
                required: true,
            },
            lblcancel: String,
            lblarchive: String,
            lblaccept: String,
        },
        components: {
            FriendlyButton,
            Popup,
        },
        computed: {
            labelCancel: function () {
                return this.$t(this.lblcancel || 'btn-cancel');
            },
            labelArchive: function () {
                return this.$t(this.lblarchive || 'btn-archive');
            },
            labelAccept: function () {
                return this.$t(this.lblaccept || 'lbl-confirm-archive');
            },
        },
    };
</script>
<template>
    <Popup :show='show' :close='close'>
        <template #title>
            {{ $t('h-confirm-archive') }}
        </template>
        <slot></slot>

        <label class='flex items-center'>
            <input v-model='confirm.confirmed' type='checkbox' id='confirmArchiveCheckbox' class='mb-1'>
            <span class='ml-2 inline-block'>{{ labelAccept }}</span>
        </label>
        <template #buttons>
            <FriendlyButton
                :label='labelCancel'
                :action='close'
                symbol='times'
                square
                secondary
                extra-small
                no-margin
                class='mr-2'
            />
            <FriendlyButton
                :label='labelArchive'
                :action='proceed'
                symbol='check'
                :disabled='!confirm.confirmed'
                square
                extra-small
                no-margin
            />
        </template>
    </Popup>
</template>
