<script setup>
import { ref, computed } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    format: {
        type: String,
        default: '',
    },
    fullwidth: Boolean,
    textSize: {
        type: String,
        // it uses tailwind sizing
        default: '2xl',
    },
    rules: {
        type: String,
    },
    mode: {
        type: String,
        default: 'eager',
    },
    autocomplete: String,
});

const showPassword = ref(false);

const inputRef = ref(null);

/**
 * Fix to remove background color added by 1Password extension when field is filled.
 *
 * Keep the attribute, 1Password uses it to detect the input is filled.
 * Set the attribute to a value not used by 1Password to remove the background color.
 * Known values used by 1Password: "light", "dark".
 */

function fix1PasswordFilled () {
    if (inputRef.value && inputRef.value.hasAttribute('data-com-onepassword-filled')) {
        inputRef.value.setAttribute('data-com-onepassword-filled', 'none');
    }
}

const modes = {
    passive: () => [],
    lazy: ({ meta, errorMessage }) => ['change'],
    aggressive: () => ['input', 'blur'],
    eager: ({ meta, errorMessage }) => errorMessage.value ? ['input', 'blur'] : ['change', 'blur'],
};

const { value: inputValue, errorMessage, handleChange, meta } = useField(
    props.name,
    props.rules,
    {
        validateOnValueUpdate: false,
        initialValue: props.value,
    });

const eventListeners = computed(() => {
    const modeFunction = modes[props.mode];
    const triggers = modeFunction({ meta, errorMessage });

    const listeners = {
        blur: handleBlur,
    };

    triggers.forEach(event => {
        if (event === 'input') {
            listeners[event] = (e) => {
                inputValue.value = e.target.value;
                handleChange(e);
            };
        } else if (event === 'change') {
            listeners[event] = handleChange;
        }
    });

    return listeners;
});

function handleBlur (event) {
    fix1PasswordFilled();
    handleChange(event);
}
</script>

<template>
    <div class='friendly-input-box' :class='{ "has-error": !!errorMessage, success: meta?.valid, fullwidth: fullwidth }'>
        <label :for='name'>{{ $t(label) }}</label>
        <div class='friendly-input' :class='`text-${textSize}`'>
            <input
                class='friendly-input-input'
                :name='name'
                :id='name'
                :type='type === "password" && showPassword ? "text": type'
                :value='inputValue'
                :placeholder='placeholder'
                v-on='eventListeners'
                ref='inputRef'
                :autocomplete='autocomplete'
            >
            <div class='friendly-input-format' v-if='placeholder'>
                <span>{{ placeholder }}</span>
            </div>
            <span v-if='type === "password"' class='fa fa-eye text-blue-400 text-xl p-3 m-0 flex items-center cursor-pointer' @click='showPassword = !showPassword' :class='{ "fa-eye-slash": showPassword }'></span>
        </div>
        <p class='friendly-input-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped lang='postcss'>
.friendly-input-box {
    display: inline-block;
    text-align: left;
    position: relative;
    min-width: 300px;

    &.fullwidth {
        width: 100%;
    }
}
label {
    @apply text-blue-400;
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    transition: color linear 100ms;
}
.friendly-input-errors {
    @apply text-red-300 ml-2 inline-block font-bold text-sm transition-all mt-1;
}
.friendly-input {
    @apply border-solid border-blue-400;
    border-width: 3px;
    border-radius: 5px;
    height: 44px;
    transition: border-color linear 100ms;
    display: flex;
    position: relative;
}

.friendly-input-input {
    width: 100%;
}

.friendly-input-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @apply text-blue-200;
    opacity: 1; /* Firefox */
}

.friendly-input-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @apply text-blue-200;
}

.friendly-input-input::-ms-input-placeholder { /* Microsoft Edge */
    @apply text-blue-200;
}

.friendly-input-format {
    position: absolute;
    display: flex;
    align-items: center;
}

.friendly-input-input,
.friendly-input-format {
    @apply text-blue-300;
    padding: 0;
    margin: 0;
    padding-left: 13px;
    border: none;
    outline: none;
    width: calc(100% - 26px);
    font-family: 'Roboto', sans-serif;
    white-space: pre;
    line-height: 22px;
    background: transparent;
    transition: color linear 100ms;
}
.friendly-input-format {
    color: #CCC;
    z-index: -1;
    top: 8px;
}
.validated .friendly-input-input {
    color: #3dcc64;
}
.validated label {
    @apply text-red-300;
}
.validated .friendly-input {
    @apply border-green-300;
}
.has-error label {
    @apply text-red-300;
}
.has-error .friendly-input {
    @apply border-red-300;
}
.has-error .friendly-input-input {
    @apply text-red-300;
}
</style>
