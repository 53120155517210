<script>
    export default {
        name: 'SodaTermsFr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>Grâce au service SODA de CodaBox, vous pouvez recevoir automatiquement dans votre environnement des fichiers générés par les secrétariats sociaux relatifs au calcul des fiches de salaires. Les fichiers SODA sont importés dans votre solution comptable en tant qu’opérations diverses. Ce service vous permet donc d’automatiser la réception et l’encodage des documents comptables que vos clients reçoivent sous la forme papier de la part de leur secrétariat social. Pour obtenir les fichiers SODA, votre client doit avoir au moins un mandat CODA signé.</p>

        <p>Notez que les fichiers SODA sont destinés aux ventilations comptables et ne comprennent donc pas de données de facturation entre le secrétariat social et le client.</p>

        <p>Dès la réception de votre commande, le mandat SODA aura un état Not-Signed ou Signed suivant le statut du mandat CODA lié. Dès que nous aurons envoyé votre commande au secrétariat social, le mandat passera en Waiting jusqu’au moment où nous recevrons le premier fichier. Le mandat sera alors Actif. Il peut donc exister un délai relativement long entre le moment de votre commande et l’activation du mandat.</p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/secretariats-sociaux-partenaires/' target='_blank'>Liste des Secrétariats Sociaux connectés </a>
        </p>

        <p>Si vous avez des clients qui sont gérés chez Securex, vous pouvez commander les fichiers SODA comme pour les autres secrétariats sociaux. Un représentant de Securex vous contactera afin de vous faire signer un contrat de livraison des fichiers SODA propre à ce secrétariat social.</p>

        <p>
            <a href='https://www.codabox.com/fr/services/soda/softwares-prets-pour-lintegration/' target='_blank'>Liste des Logiciels de comptabilité compatibles</a>
        </p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Tarifs administratifs
        </h4>

        <p>Lorsqu’un mandat SODA est activé, dès la première réception d’un fichier pour ce mandat, vous serez facturé de manière unique d’un montant de 6,00 € par mandat SODA.</p>

        <h4 class='cb-service-title'>
            Tarifs récurrents
        </h4>

        <p>Dès l’activation du service SODA pour votre fiduciaire, vous serez facturé mensuellement d’un montant forfaitaire de 67,00 €, indépendamment du nombre de mandats SODA commandés.</p>

        <h3>Conditions Générales</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex SODA (B2-C2-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
