<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import {Auth} from '@aws-amplify/auth';
import authUtils from '@/auth/utils';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
import MigrationPopup from '@/user/MigrationPopup.vue';
import utils from '@/utils';
import { Form } from 'vee-validate';

export default {
    name: 'LoginView',
    components: {
        MigrationPopup, // FIXME MIGRATION_CLEAN: this component should be removed when all users are migrated
        NavBar,
        FriendlyButton,
        FriendlyInput,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            isLoginOngoing: false,
            coreToken: '', // FIXME MIGRATION_CLEAN: should be removed
            coreData: null, // FIXME MIGRATION_CLEAN: should be removed
            loginError: '',
            showLoginError: false, // FIXME MIGRATION_CLEAN: can be removed
            showMigrationPopup: false, // FIXME MIGRATION_CLEAN: can be removed
        };
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        async performLogin (values) {
            if (this.isLoginOngoing) return;
            this.loginError = '';
            this.showLoginError = false;
            this.isLoginOngoing = true;
            // Case e-mail
            if (utils.validateEmail(values.Login) && await this.performCognitoLogin(values.Login, values.Password)) {
                this.$router.push('/home');
            } else { // FIXME MIGRATION_CLEAN: should be removed
                await this.startMigrationProcedure(values.Login, values.Password);
            }
            this.isLoginOngoing = false;
        },
        // FIXME MIGRATION_CLEAN: should be removed
        openMigrationPopup () {
            this.showMigrationPopup = true;
        },
        // FIXME MIGRATION_CLEAN: should be removed
        closeMigrationPopup () {
            this.showMigrationPopup = false;
        },
        async performCognitoLogin (login, password) {
            try {
                let cognitoUser = await Auth.signIn(login, password);
                authUtils.setAuthToken(cognitoUser.signInUserSession.accessToken.jwtToken);
                authUtils.setIdToken(cognitoUser.signInUserSession.idToken.jwtToken);
            } catch (e) {
                // FIXME MIGRATION_CLEAN: show all error messages again
                if (e.message && e.message === 'Password attempts exceeded') {
                    this.loginError = this.$t('err-login-attempts-exceeded');
                    this.showLoginError = true;
                }
                return false;
            }

            try {
                await this.$store.dispatch('loadUser');
                return true;
            } catch (e) {
                // FIXME MIGRATION_CLEAN: show all error messages again
                if (e.message === 'invalidUserConfiguration') {
                    this.loginError = this.$t('err-login-invalid-user-configuration');
                    this.showLoginError = true;
                } else {
                    this.loginError = this.$t('err-login-cant-load-user');
                    this.showLoginError = true;
                }

                if (this.showLoginError) {
                    await this.$store.dispatch('logout');
                }

                return false;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async getTokenFromCore (login, password) {
            try {
                const response = await fetch('/bff/api/user-migration/authenticate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: login,
                        password: password,
                    }),
                });
                if (!response.ok) {
                    return null;
                }
                return (await response.json()).token;
            } catch (e) {
                return null;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async getDataFromCore () {
            try {
                const infoResponse = await fetch('/bff/api/user-migration/info', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'CORE-TOKEN': this.coreToken,
                    },
                });
                if (infoResponse.status !== 200) {
                    return null;
                }
                return (await infoResponse.json());
            } catch (e) {
                return null;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async startMigrationProcedure (login, password) {
            if (this.showLoginError) return;
            this.coreToken = await this.getTokenFromCore(login, password);
            this.coreData = await this.getDataFromCore();

            if (!this.coreToken) {
                this.loginError = this.$t('err-invalid-migration-credentials');
                this.showLoginError = true;
                return;
            } else if (!this.coreData) {
                this.loginError = this.$t('err-unknown');
                this.showLoginError = true;
                return;
            }

            this.openMigrationPopup();
        },
        validateEmail: utils.validateEmail,
    },
};

</script>

<template>
    <div class='login'>
        <NavBar no-nav transparent />
        <div>
            <!-- FIXME MIGRATION_CLEAN: should be removed -->
            <MigrationPopup
                :show='showMigrationPopup'
                :close='closeMigrationPopup'
                :core-data='coreData'
                :core-token='coreToken'
            />
            <div class='w-full mb-12 text-center px-6'>
                <div class='w-full mb-12 flex justify-center'>
                    <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
                </div>
                <h1 class='font-bold'>
                    {{ $t("ttl-migration-message") }}
                </h1>
                <p class='text-xl'>
                    {{ $t("p-migration-message") }}
                </p>
                <span class='font-bold text-xl'>{{ $t("p-more-info") }}</span> <a
                    class='text-xl'
                    :href='$t("a-href-faq-migration")'
                    target='_blank'
                >{{ $t("a-faq") }}</a>
            </div>
            <div id='login-form' class='login__panel'>
                <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                    <div v-if='showLoginError && loginError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                        {{ loginError }}
                    </div>

                    <Form tag='div' @submit='performLogin' v-slot='{ values }'>
                        <FriendlyInput
                            label='lbl-login'
                            name='Login'
                            fullwidth
                            text-size='xl'
                            :value='$route.query.email && validateEmail($route.query.email) ? $route.query.email : ""'
                            rules='required'
                        />

                        <FriendlyInput
                            autocomplete='off'
                            label='lbl-password'
                            type='password'
                            name='Password'
                            fullwidth
                            class='mt-3'
                            text-size='xl'
                            rules='required'
                        />

                        <div class='mt-6'>
                            <FriendlyButton
                                label='btn-login'
                                no-margin
                                fullwidth
                                square
                                small
                                type='submit'
                            />
                        </div>

                        <router-link :to='{ name: "forgot-password", query: values.Login && validateEmail(values.Login) ? { email: values.Login } : {} }' class='inline-block mt-3'>
                            {{ $t('a-forgot-password') }}
                        </router-link>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.login__panel {
    @apply pb-64 my-auto mx-auto;
    max-width: 430px;
    width: 90%;
}
</style>
