import 'whatwg-fetch';
import utils from '@/utils.js';
import Prismic from 'prismic-javascript';
import bus from '@/bus.js';
import authUtils from '../auth/utils';
import { Auth } from '@aws-amplify/auth';

const SOFTWARE_TOKEN = 'e7f9bb14-db2a-4d4b-ae88-e08889213cc5';
const PRISMIC_API = 'https://codabox.prismic.io/api/v2';

var Api = {
    token: null,
    idToken: null,

    getBaseUrl: function () {
        return '/';
    },

    /* thin wrapper around fetch with Auth && X-Software-Company header. */
    fetch: function (url, opts) {
        url = Api.getBaseUrl() + url;
        opts = utils.clone(opts);
        opts.headers = utils.extend({
            'X-Software-Company': SOFTWARE_TOKEN,
            'Accept-Language': 'en-US',
        }, opts.headers);

        if (Api.token && Api.idToken) {
            opts.headers = utils.extend({
                'Authorization': 'Bearer ' + Api.token,
                'ID-TOKEN': Api.idToken,
            }, opts.headers);
        }

        if (opts.method === 'GET' || opts.method === 'HEAD') {
            delete opts['body'];
        }
        return fetch(url, opts);
    },

    /*
     *  Api.rpc(method, url, data, opts).then(...)
     *
     *  # RPC requests.
     *
     *  - automatically authenticated
     *
     *  ## Params
     *
     *  method: 'GET' | 'POST' | ...
     *  url: 'foo/bar/...',
     *  data: {foo:42},
     *  opts: {
     *      // fetch options
     *      headers: {}
     *      ...
     *  }
     *
     *  ## Returns
     *
     *  in case of success:
     *    - Return resolved Promise with
     *    - The json object if expecting a json
     *    - Blob / string / etc if other accept header
     *    - empty string if expected no content
     *  in case of error:
     *    - Return failed Promise with structure
     *     {
     *       error: 'server' | 'api' | 'network'
     *       status: 404 | 500 | ...
     *       body:
     *          - The json object if api error and expecting a json
     *          - null if server error
     *          - The fetch response object if network error
     *     }
     */

    rpc: function (method, url, data, opts) {
        opts = utils.clone(opts);
        opts.method = method;

        opts.headers = utils.extend({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }, opts.headers);

        opts.body = JSON.stringify(data || {});

        return Api.fetch(url, opts)
            .catch((err) => {
                return Promise.reject({
                    error: 'network',
                    status: 0,
                    body: err,
                });
            })
            .then((res) => {
                if (res.status >= 500) {
                    return Promise.reject({
                        error: 'server',
                        status: res.status,
                        body: null,
                    });
                } else if (res.status === 404) {
                    return res.json()
                        .catch(err => {
                            console.error('Error while parsing JSON', err);
                            return Promise.reject({
                                error: 'server',
                                status: res.status,
                                body: null,
                            });
                        })
                        .then(body => {
                            return Promise.reject({
                                error: 'server',
                                status: res.status,
                                body: body,
                            });
                        });
                } else if (res.status in utils.Set(200, 201, 202, 203)) {
                    if (opts.headers.Accept === 'application/json') {
                        return res.json().catch((err) => {
                            console.error('Error while parsing JSON', err);
                            return {};
                        });
                    } else {
                        return res.body;
                    }
                } else if (res.status > 203 && res.status < 300) {
                    // success with no content.
                    return '';
                } else if (res.status === 401) {
                    return res.json()
                        .catch(err => {
                            console.error('Error while parsing JSON', err);
                            return {};
                        })
                        .then(async body => {
                            // Try to refresh the token + redo the call
                            try {
                                const currentSession = await Auth.currentSession();
                                authUtils.setAuthToken(currentSession.accessToken.jwtToken);
                                authUtils.setIdToken(currentSession.idToken.jwtToken);
                                return Api.rpc(method, url, data, opts);
                            } catch (err) {
                                bus.emit('session-expired', null);
                                return Promise.reject({
                                    error: 'api',
                                    status: res.status,
                                    body: body,
                                });
                            }
                        });
                } else {
                    if (opts.headers.Accept === 'application/json') {
                        return res.json()
                            .catch((err) => {
                                console.error('Error while parsing JSON', err);
                                return {};
                            })
                            .then((body) => {
                                return Promise.reject({
                                    error: 'api',
                                    status: res.status,
                                    body: body,
                                });
                            });
                    } else {
                        return Promise.reject({
                            error: 'api',
                            status: res.status,
                            body: res.body,
                        });
                    }
                }
            });
    },

    /*  Api.cache(duration, method, url, data, opts).then(...)
     *
     *  # Cached RPC requests.
     *
     *  - Same as RPC except it will re-use results of RPCs
     *    up to `duration` msec. old.
     *  - If duration is < 0, it will always reuse the latest
     *    cached result.
     *  - Cache depends on method, url, data & auth token.
     *  - Results are considered 'in cache', as soon as the
     *    request is started, not when the RPC has returned.
     *    This means that quick RPC done in succession will
     *    resolve in batch.
     *
     */

    cached: function (duration, method, url, data, opts) {
        var key = Api.token + '|' + method + '|' + url + '|' + JSON.stringify(data || {});

        var cached = Api.__cache__[key];

        if (cached && (duration < 0 || Date.now() <= cached.time + duration)) {
            return cached.value.then((res) => { return utils.deepClone(res); });
        }

        var value = Api.rpc(method, url, data, opts);
        Api.__cache__[key] = {
            time: Date.now(),
            value: value,
        };

        return value;
    },

    __cache__: {},

    /* -- PRISMIC API -- */

    getPrismicApi: function () {
        return Prismic.getApi(PRISMIC_API);
    },

    /* -- CodaBox Api Wrapper -- */

    login: function (username, password) {
        return Api.rpc('POST', 'core/internal/token-auth', {
            'data': {
                username,
                password,
            },
        });
    },

    passwordReset: function (email) {
        return Api.rpc('POST', 'core/v2/password/reset/', {
            'email': email,
        });
    },

    passwordResetConfirm: function (uuid, token, pw, pw2) {
        return Api.rpc('POST', 'core/v2/password/reset/confirm/', {
            'uid': uuid,
            'token': token,
            'new_password': pw,
            're_new_password': pw2,
        });
    },

    changePassword: function (password, newPassword, newPassword2) {
        return Api.rpc('POST', 'core/v2/password/', {
            'current_password': password,
            'new_password': newPassword,
            're_new_password': newPassword2,
        });
    },

    signup: function (username, password, token) {
        return Api.rpc('POST', 'core/v2/user-signup/', {
            'invitation_token': token,
            'username': username,
            'password': password,
        });
    },

    getSignupInfo: function (token) {
        return Api.rpc('GET', 'core/v2/user-signup/?invitation=' + token);
    },

    signupConfirm: function (invitation, confirmation) {
        return Api.rpc('POST', 'core/v2/user-signup-confirm/', {
            'invitation_token': invitation,
            'confirmation_token': confirmation,
        });
    },

    // file email subscription

    unsubscribe: function (token) {
        return Api.rpc('GET', `core/v2/unsubscribe/${token}/`);
    },

    // email verifications for clients

    getEmailVerification: function (token) {
        return Api.rpc('GET', `core/v2/verify-email/${token}/`);
    },

    submitEmailVerification: function (token, state, challenge = '') {
        return Api.rpc('PUT', `core/v2/verify-email/${token}/`, {
            state: state,
            challenge_answer: challenge,
        });
    },

    restartEmailVerification: function (token) {
        return Api.rpc('PUT', `core/v2/verify-email/restart/${token}/`);
    },

};

export default Api;
