<template>
    <div class='text-center flex flex-col items-center justify-center mb-auto'>
        <h1 class='mt-20'>
            {{ $t('ttl-connect-api-page') }}
        </h1>
        <div class='mt-12 flex flex-col '>
            <div class='border border-solid border-grey-200 p-6 inline-block rounded-md'>
                <div class='inline-block'>
                    <div class='info-row'>
                        <span>{{ $t('ttl-connect-api-software-name') }}</span> {{ consent.softwareName }}
                    </div>
                    <div class='info-row'>
                        <span>{{ $t('ttl-connect-api-accountant-name') }}</span> {{ consent.accountantName }}
                    </div>
                </div>
            </div>
            <div class='mt-6'>
                {{ $t('lbl-connect-api-disclaimer') }}
            </div>
            <div class='mt-12 inline-block items-center'>
                <FormToggle
                    :value='acceptTerms'
                    name='terms-toggle'
                    :edit='true'
                    @change='acceptTerms=!acceptTerms'
                >
                    <Translated class='ml-3'>
                        <template #en>
                            I have read and accept the terms and conditions (<a href='https://www.codabox.com/fr/connect-gtc-fr/' target='_blank'>FR</a> - <a href='https://www.codabox.com/nl/connect-gtc-nl/' target='_blank'>NL</a>)
                        </template>
                        <template #nl>
                            Ik heb de <a href='https://www.codabox.com/nl/connect-gtc-nl/' target='_blank'>algemene voorwaarden <i class='fa fa-download ml-1'></i></a> gelezen en geaccepteerd.
                        </template>
                        <template #fr>
                            J’ai lu et j’accepte les <a href='https://www.codabox.com/fr/connect-gtc-fr/' target='_blank'>conditions générales <i class='fa fa-download ml-1'></i></a>
                        </template>
                    </Translated>
                </FormToggle>
            </div>
            <div class='mt-6 grid grid-cols-2 gap-6'>
                <custom-button variant='grey' @click='refuse' id='refuse'>
                    {{ $t('btn-connect-api-refuse') }}
                </custom-button>
                <custom-button :disabled='!acceptTerms' @click='confirm' id='confirm'>
                    {{ $t('btn-connect-api-confirm') }}
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/Button';
import notify from '../notify.js';
import { gql } from '@apollo/client/core';
import Translated from '@/components/Translated';
import FormToggle from '@/components/FormToggle.vue';

export default {
    props: {
        consentId: String,
        onDone: Function,
        onError: Function,
        onSuccess: Function,
    },
    components: {
        FormToggle,
        CustomButton,
        Translated,
    },
    data () {
        return {
            acceptTerms: false,
            consent: {},
        };
    },
    watch: {
        consentId () {
            this.getConsentObject();
        },
    },
    created () {
        this.getConsentObject();
    },
    methods: {
        async getConsentObject () {
            try {
                const { data } = await this.$apollo.query({
                    query: gql`query accountingSoftwareConsent($consentId: String!) {
                        accountingSoftwareConsent(consentId:$consentId) {
                            id
                            userId
                            softwareName
                            accountantId
                            accountantName
                            confirmationUri
                            state
                            created
                            modified
                            redirectUri
                            softwareId
                            accountantCompanyNumber
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (data.accountingSoftwareConsent.state !== 'unconfirmed') {
                    notify.success(this.$t('lbl-connect-api-incorrect-state'));
                    this.onSuccess(data.accountingSoftwareConsent);
                }
                this.consent = data.accountingSoftwareConsent;
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
                this.onError(this.consent);
            }
        },
        async refuse () {
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation refuseAccountingSoftwareConsent($consentId: String!) {
                        refuseAccountingSoftwareConsent(consentId: $consentId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (!data.refuseAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-refuse-success'));
                    if (this.onSuccess) {
                        this.onSuccess(this.consent);
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
            }
            this.onDone(this.consent);
        },
        async confirm () {
            try {
                const {data} = await this.$apollo.mutate({
                    mutation: gql`mutation acceptAccountingSoftwareConsent($consentId: String!) {
                        acceptAccountingSoftwareConsent(consentId: $consentId) {
                            errors { code, detail, source { pointer } }
                        }
                    }`,
                    variables: {
                        consentId: this.consentId,
                    },
                });
                if (!data.acceptAccountingSoftwareConsent.errors) {
                    notify.success(this.$t('lbl-connect-api-confirm-success'));
                    if (this.onSuccess) {
                        this.onSuccess(this.consent);
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (e) {
                notify.error(this.$t('err-connect-api-not-found'));
            }
            this.onDone(this.consent);
        },
    },
};
</script>

<style lang="postcss" scoped>
.info-row {
    @apply mb-2;
}

.info-row:last-child {
    @apply mb-0;
}

.info-row span {
    @apply font-bold mr-2;
}
</style>
