<template>
    <div
        class='flex flex-col'
    >
        <label v-if='label' class='flex'>
            <span class='truncate label'>
                {{ label }}<span v-if='isRequired' class='required'>*</span>
            </span>
            <span v-if='$slots.info' class='ml-2 font-normal'>
                <tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <slot name='info'></slot>
                    </template>
                </tooltip>
            </span>
        </label>

        <div :class='{ "w-96": !fullwidth }'>
            <VueDatePicker
                :model-value='dateValue'
                @update:model-value='onChange'
                :min-date='minDate'
                :max-date='maxDate'
                prevent-min-max-navigation
                :range='range'
                :enable-time-picker='false'
                :locale='$t.locale'
                auto-apply
                :format='format'
                :placeholder='placeholder'
                :disabled='disabled'
                :start-date='startDate'
            />
            <p class='form-input-errors' v-show='errorMessage'>
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script>
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import Tooltip from '@/components/Tooltip';
    import { useField } from 'vee-validate';
    import { computed } from 'vue';

    export default {
        name: 'DatePickerComponent',
        // VUE-3-MIGRATION-DIRT
        compatConfig: {
            COMPONENT_V_MODEL: false,
            COMPONENT_ASYNC: false,
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            label: String,
            placeholder: String,
            edit: {
                type: Boolean,
                default: false,
            },
            rules: {
                type: String,
                default: () => '',
            },
            minDate: String || Date,
            maxDate: String || Date,
            fullwidth: Boolean,
            range: Boolean,
            disabled: Boolean,
            startDate: {
                type: String || Date,
                default: null,
            },

        },
        components: {
            VueDatePicker,
            Tooltip,
        },
        data () {
            return {
                date: null,
            };
        },
        emits: ['change'],
        computed: {
            isRequired () {
                return this.rules && this.rules.includes('required') && this.edit;
            },
        },
        methods: {
            onChange (value) {
                this.$emit('change', value);
                this.handleChange(value);
            },
            formatDate (date) {
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();

                return `${year}-${month}-${day}`;
            },
            format (value) {
                if (this.range) {
                    // In case of a range picker, value is [Date, Date]
                    return `${this.formatDate(value[0])} - ${this.formatDate(value[1])}`;
                } else {
                    // Otherwise, value is a Date
                    return this.formatDate(value);
                }
            },
        },
        setup (props) {
            // FIXME: ! avoid mixing option api (vue2) and composition api (vue3, setup) and fix it by completely migrate this component to vue3 with composition api !

            // Create a computed property that returns the rules from props to be reactive
            const computedRules = computed(() => props.rules);

            let { value: dateValue, handleChange, errorMessage } = useField(
                props.name,
                computedRules,
                {
                    validateOnValueUpdate: false,
                    label: props.label,
                });

            return {
                dateValue,
                handleChange,
                errorMessage,
            };
        },
    };
</script>

<style scoped>
:root {
    /*General*/
    --dp-font-family: 'Roboto', sans-serif;
    --dp-border-radius: 4px; /*Configurable border-radius*/
    --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
    --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/

    /*Sizing*/
    --dp-button-height: 35px; /*Size for buttons in overlays*/
    --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
    --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
    --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
    --dp-cell-size: 35px; /*Width and height of calendar cell*/
    --dp-cell-padding: 5px; /*Padding in the cell*/
    --dp-common-padding: 15px; /*Common padding used*/
    --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
    --dp-input-padding: 6px 30px 6px 12px; /*Padding in the input*/
    --dp-menu-min-width: 260px; /*Adjust the min width of the menu*/
    --dp-action-buttons-padding: 13px 15px; /*Adjust padding for the action buttons in action row*/
    --dp-row-margin:  10px 10px; /*Adjust the spacing between rows in the calendar*/
    --dp-calendar-header-cell-padding:  0.5rem; /*Adjust padding in calendar header cells*/
    --dp-two-calendars-spacing:  10px; /*Space between multiple calendars*/
    --dp-overlay-col-padding:  3px; /*Padding in the overlay column*/
    --dp-time-inc-dec-button-size:  32px; /*Sizing for arrow buttons in the time picker*/
    --dp-menu-padding: 10px 10px; /*Menu padding*/

    /*Font sizes*/
    --dp-font-size: 1rem; /*Default font-size*/
    --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
    --dp-time-font-size: 0.8rem; /*Font size in the time picker*/

    /*Transitions*/
    --dp-animation-duration: 0.1s; /*Transition duration*/
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
    --dp-transition-timing: ease-out; /*Timing on slide animations*/
}

.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #0d52ff;
    --dp-primary-disabled-color: #C2D4FF;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #70c36b;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #FF8900;
    --dp-marker-color: #FF8900;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

input[disabled].dp__disabled {
    cursor: not-allowed;
}

.form-input-errors {
    @apply text-red-300 ml-0 inline-block font-bold text-sm transition-all mt-1 mb-0 mt-2;
}
</style>
