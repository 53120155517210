<script>
    export default {
        name: 'purchase-terms-fr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>Le service VOILA est un service supplémentaire intégrant la gamme CodaBox. Vous pouvez désormais recevoir les factures d'achat de vos clients, envoyées via différents canaux (Peppol, Zoomit, ...), de manière électronique. Votre client n'a de ce fait plus besoin de vous transmettre ces factures d’achat.</p>

        <p>Vous pouvez commander le service via MyCodaBox sous 'MANDATS & SERVICES CLIENTS - VOILA'. Ceci peut être fait pour l'ensemble de vos clients en même temps ou pour chaque client de manière individuelle.</p>

        <p>IMPORTANT: Prévenez vos clients qu'ils doivent accepter le service VOILA CodaBox eux-mêmes. Il s'agit d'une démarche importante en vue de maximaliser les chances d'inscription à ce service.</p>

        <p>Comment ce service fonctionne-t-il pour vos clients&nbsp;?</p>

        <ol>
            <li>Lorsque vous commandez le service pour vos clients, il leur sera demandé par email d'accepter le service VOILA.</li>
            <li>Vos clients seront informés par CodaBox sur les étapes qui suivent l'acceptation du service et ce que cela implique pour eux.</li>
            <li>Une fois que vos clients auront accepté le service, CodaBox utilisera cette confirmation pour enregistrer vos clients sur les différents réseaux de facturation électronique.</li>
            <li>Vos clients n'auront plus besoin de vous transmettre les factures qu'ils reçoivent par ces canaux. Dès qu'un fournisseur envoie une facture, CodaBox la transmet à votre client via le mode de livraison choisi (portail client du comptable ou email). Vous recevrez automatiquement les factures électroniques que CodaBox vous enverra par le mode de livraison que vous aurez choisi, comme pour la réception des CODA.</li>
        </ol>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Tarifs administratifs
        </h4>

        <p>Aucun frais administratif ne sera facturé.</p>

        <h4 class='cb-service-title'>
            Tarifs récurrents
        </h4>

        <p>Jusqu'au 1er juillet 2022, le produit VOILA est offert gratuitement. À partir du 1er juillet 2022, la liste de prix ci-dessous pour le produit VOILA s'applique. Jusqu'à nouvel ordre, CodaBox appliquera une remise de 65% sur les prix standard indiqués. Si CodaBox réduit ou supprime cette remise, CodaBox vous en informera au moins trois mois à l'avance.</p>
        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 numéros d’entreprise</td>
                    <td>3,62 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 numéros d’entreprise</td>
                    <td>4,53 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 numéros d’entreprise</td>
                    <td>5,01 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 numéros d’entreprise</td>
                    <td>6,28 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 numéros d’entreprise</td>
                    <td>7,53 €/mois/numéro d’entreprise</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 numéros d’entreprise</td>
                    <td>9,37 € /mois/numéro d’entreprise</td>
                </tr>
            </tbody>
        </table>

        <p>Pour l’établissement du tarif correct, il est également tenu compte du nombre de services actifs pour CODA et CARO. Pour plus de détails veuillez consulter les Conditions Générales, via le lien ci-dessous.</p>

        <h3>Conditions Générales</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex VOILA (B3-C3-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style>
    .hl { color: rgb(153,204,0); }

    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
