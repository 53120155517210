<script>
    export default {
        name: 'SodaTermsNl',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Beschrijving van de dienst
        </h3>

        <p>Dankzij de SODA-dienst van CodaBox kunt u automatisch, in uw werkomgeving, documenten ontvangen die door de sociale secretariaten worden aangeleverd betreffende de berekening van loonfiches. Deze SODA-bestanden worden in uw boekhoudsoftware geïmporteerd als diverse verrichtingen. De ontvangst en codering van boekhoudkundige documenten die uw klanten op papier ontvangen van hun sociaal secretariaat kunt u met deze dienst dus automatiseren. Om de SODA-bestanden te verkrijgen, moet uw klant minstens een CODA-mandaat ondertekend hebben.</p>

        <p>Merk op dat de SODA-bestanden bedoeld zijn voor de boekhoudkundige uitsplitsing en dus geen facturatiegegevens bevatten tussen het sociaal secretariaat en de klant.</p>

        <p>Vanaf de ontvangst van uw bestelling zal het SODA-mandaat een status Not-Signed of Signed hebben naargelang de status van het gelinkte CODA-mandaat. Zodra wij u bestelling hebben doorgegeven aan het sociaal secretariaat, krijgt het mandaat de status Waiting totdat wij het eerste bestand ontvangen. Het mandaat zal vanaf dan Active zijn. Er kan dus een relatief lange termijn zitten tussen het moment van uw bestelling en de activering van het mandaat.</p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/de-sociale-secretariaten-partners/' target='_blank'>Overzicht van de aangesloten sociale secretariaten </a>
        </p>

        <p>Indien u klanten hebt die door Securex worden beheerd, kunt u de SODA-bestanden bestellen zoals voor de andere sociale secretariaten. Een vertegenwoordiger van Securex zal dan contact met u opnemen voor de ondertekening van het contract voor de levering van SODA-bestanden eigen aan dat sociaal secretariaat.</p>

        <p>
            <a href='https://www.codabox.com/diensten/soda/software-klaar-voor-integratie/' target='_blank'>Overzicht van de compatibele boekhoudsoftware</a>
        </p>

        <h3>Tarieven</h3>

        <h4 class='cb-service-title'>
            Administratieve kosten
        </h4>

        <p>Zodra een SODA-mandaat geactiveerd is, wordt, na de eerste ontvangst van een bestand voor dit mandaat, een eenmalig bedrag van 6,00 € per SODA-mandaat gefactureerd.</p>

        <h4 class='cb-service-title'>
            Recurrente kosten
        </h4>

        <p>Vanaf de activering van de SODA-dienst voor uw fiduciaire wordt u maandelijks een forfaitair bedrag van 67,00 € gefactureerd, ongeacht het aantal bestelde SODA-mandaten.</p>

        <h3>Algemene voorwaarden</h3>

        <p>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex SODA (B2-C2-D).pdf' target='_blank'>Algemene voorwaarden van de dienst</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }
</style>
