<script>
import { gql } from '@apollo/client/core';
import Popup from '@/clientcomponents/Popup.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import notify from '@/notify.js';
import Translated from '@/components/Translated';

export default {
    props: {
        id: String,
        small: Boolean,
        extraSmall: Boolean,
        disabled: Boolean,
        alterOnSuccess: Boolean,
    },
    components: {
        Popup,
        FriendlyButton,
        Translated,
    },
    data () {
        return {
            popupShown: false,
            deactivateVoilaMandate: {},
            success: false,
        };
    },
    emits: ['success', 'done'],
    methods: {
        async deactivateVoila () {
            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation DeactivateVoilaMandate($id: String!) {
                    deactivateVoilaMandate(id: $id, reason: "Manual action by Fiduciary in MyCodaBox") {
                        error {
                            code,
                            message
                        }
                    }
                }`,
                variables: {
                    id: this.id,
                },
            });
            this.deactivateVoilaMandate = data.deactivateVoilaMandate;

            if (this.deactivateVoilaMandate.error) {
                notify.error(this.$t(this.deactivateVoilaMandate.error.message));
            } else {
                notify.success(this.$t('suc-mandate-deactivated'));
                this.$emit('success');
                this.success = true;
            }
            this.closePopup();
            this.$emit('done');
        },
        openPopup () {
            this.popupShown = true;
        },
        closePopup () {
            this.popupShown = false;
        },
    },
};
</script>

<template>
    <div>
        <FriendlyButton
            class='voila-deactivation-button'
            label='btn-action-voila-done'
            symbol='check'
            :action='openPopup'
            :extra-small='extraSmall'
            :small='small'
            disabled
            no-margin
            square
            v-if='alterOnSuccess && success'
        />
        <FriendlyButton
            class='voila-deactivation-button'
            label='btn-deactivate-voila'
            :action='openPopup'
            :extra-small='extraSmall'
            :small='small'
            :disabled='disabled'
            no-margin
            square
            v-else
        />
        <Popup :show='popupShown' :close='closePopup' small class='voila-deactivation-modal'>
            <template #title>
                {{ $t('ttl-deactivate-voila') }}
            </template>

            <p>
                <Translated>
                    <template #en>
                        Canceling the VOILA service stops the further administrative follow-up and/or the delivery of the purchase invoices of this client.<br>We will also inform your client of this termination when the service was active.
                    </template>
                    <template #nl>
                        Het annuleren van de dienst VOILA stopt de verdere administratieve opvolging en/of de levering van de aankoopfacturen van deze klant.<br>Wij brengen ook uw klant op de hoogte van deze stopzetting wanneer de dienst actief was.
                    </template>
                    <template #fr>
                        L'annulation du service VOILA interrompt le suivi administratif ultérieur et/ou la livraison des factures d'achat de ce client.<br>Nous informerons également votre client de cette résiliation quand le service était actif.
                    </template>
                </Translated>
            </p>

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel-voila-deactivation'
                    class='voila-deactivation-popup-close-button'
                    :action='closePopup'
                    symbol='times'
                    small
                    secondary
                />
                <FriendlyButton
                    label='btn-confirm-voila-deactivation'
                    :action='deactivateVoila'
                    small
                    symbol='check'
                    class='voila-deactivation-popup-submit-button'
                />
            </template>
        </Popup>
    </div>
</template>
