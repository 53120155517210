<script>
    export default {
        name: 'CodaTermsEn',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>CodaBox retrieves any given business’ electronic bank statements, in CODA-format, and delivers them to your accountancy office.</p>

        <p>You can order CODA for your clients directly in MyCodaBox, and we will handle the further administrative process with each bank: generation of the correct bank mandates, arranging for your clients to sign the mandates, … . You can follow-up the status of the process also via MyCodaBox.</p>

        <p>CodaBox will receive the first CODA file when the bank has validated and activated the mandate and as soon as there has been a transaction on the account. CodaBox then checks the numbering, the version and the balances, creates a PDF file which collates all the information from the CODA file, and deliver the CODA files to you in a format and structure compatible with your accounting software.</p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>Mandates: 24 € per mandate</p>

        <p>Optional service – Delivery of CODA-files to your client via SFTP: 100 € per SFTP-server (<a href='https://faq.codabox.com/en/support/solutions/articles/75000087150-coda-also-delivered-to-your-client#link' target='_blank'>more info</a>)</p>

        <h4 class='cb-service-title'>
            Recurrent Costs
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 current accounts</td>
                    <td>3,62 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 current accounts</td>
                    <td>4,53 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 current accounts</td>
                    <td>5,01 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 current accounts</td>
                    <td>6,28 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 current accounts</td>
                    <td>7,53 €/month/account</td>
                </tr>
                <tr>
                    <td>For 1 and 2 current accounts</td>
                    <td>9,37 €/month/account</td>
                </tr>
            </tbody>
        </table>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CODA (B1-C1-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CODA (B1-C1-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
</style>
