<script>
    import ClientTransferList from './mixins/ClientTransferList.vue';
    import Dropdown from '@/components/DropdownV2';
    import DropdownItem from '@/components/DropdownItem';
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ExactEmail from '@/components/ExactEmail.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import Popup from '@/clientcomponents/Popup';

    export default {
        name: 'ClientTransferPlatformList',
        mixins: [ClientTransferList],
        components: {
            ExactEmail,
            ContentBox,
            Dropdown,
            DropdownItem,
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            Popup,
        },
        data () {
            return {
                transferTypesFilter: ['platformTransfer', 'fusion'],
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
            fiduciaryId () {
                return this.currentFiduciary.id;
            },
        },
    };
</script>

<template>
    <div>
        <Popup
            :show='isCancelPopupDisplayed'
            :close='closeCancelPopup'
        >
            <template #title>
                {{ $t('ttl-cancel-client-transfer-request-popup') }}
            </template>

            <div>{{ $t('p-cancel-client-transfer-request-popup') }}</div>

            <template #buttons>
                <div class='flex'>
                    <FriendlyButton
                        label='btn-cancel-client-transfer-request-close'
                        class='mr-3 ml-auto'
                        :action='closeCancelPopup'
                        small
                        no-margin
                        secondary
                    />
                    <FriendlyButton
                        label='btn-cancel-client-transfer-request-confirms'
                        small
                        no-margin
                        symbol='check'
                        :action='cancelConfirmation'
                    />
                </div>
            </template>
        </Popup>

        <div class='my-6 text-gray-500'>
            {{ $t('p-client-platform-transfer-request-intro') }}
        </div>

        <content-box>
            <div>
                <div class='flex mb-3'>
                    <Dropdown
                        v-model='stateCriteria'
                        :name='$t("ctr-state-filter")'
                        class='z-50'
                        nullable
                        icon='sort'
                        id='ctr-state-filter'
                        default-value='all'
                    >
                        <DropdownItem id='all' :name='$t("ctr-display-state-all")' default class='whitespace-nowrap'>
                            {{ $t('ctr-display-state-all') }}
                        </DropdownItem>
                        <DropdownItem id='open' :name='$t("ctr-display-state-open")' class='whitespace-nowrap'>
                            {{ $t('ctr-display-state-open') }}
                        </DropdownItem>
                        <DropdownItem id='closed' :name='$t("ctr-display-state-closed")' class='whitespace-nowrap'>
                            {{ $t('ctr-display-state-closed') }}
                        </DropdownItem>
                    </Dropdown>
                </div>

                <List
                    :loading='loading'
                    id='ctr-platform-list'
                    tooltip-fix
                    class='cb-bordered'
                    :class='{"table-responsive": $store.state.gui === "mobile"}'
                >
                    <template #columns>
                        <ListColumn>{{ $t('th-client-code') }}</ListColumn>
                        <ListColumn>{{ $t('th-client-name') }}</ListColumn>
                        <ListColumn>{{ $t('th-enterprise-number') }}</ListColumn>
                        <ListColumn>{{ $t('th-original-platform') }}</ListColumn>
                        <ListColumn v-if='isFiduExactOnline'>
                            {{ $t('lbl-exact-email') }}
                        </ListColumn>
                        <ListColumn>{{ $t('th-state-date') }}</ListColumn>
                        <ListColumn>{{ $t('th-transfer-state') }}</ListColumn>
                        <ListColumn>{{ $t('th-actions') }}</ListColumn>
                    </template>
                    <template #rows>
                        <ListRow
                            v-for='(ctr, i) in clientTransferRequests'
                            :key='i'
                            :landmark='displayRowLandmark(i)'
                            :background='Number.isInteger(((i+1)/2))'
                        >
                            <ListItem>
                                {{ ctr.clientCode }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.enterpriseName }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.enterpriseNumber }}
                            </ListItem>
                            <ListItem>
                                {{ ctr.originalFiduciaryName }}
                            </ListItem>
                            <ListItem v-if='isFiduExactOnline'>
                                <ExactEmail :email='ctr.exactEmail' />
                            </ListItem>
                            <ListItem>
                                {{ dateFormat(ctr.stateModifiedAt) }}
                            </ListItem>
                            <ListItem>
                                {{ $t(getStateLabel(ctr.state)) }}
                            </ListItem>
                            <ListItem>
                                <div class='flex'>
                                    <FriendlyButton
                                        v-if='ctr.state === "new" || ctr.state === "waitingFiduFeedback" || ctr.state === "waitingClientConfirmation"'
                                        label='btn-cancel-client-transfer-request-init'
                                        micro
                                        no-margin
                                        square
                                        :action='() => cancelRequest(ctr)'
                                    />
                                    <router-link
                                        v-if='ctr.state === "done" && fiduciaryId === ctr.currentFiduciaryId'
                                        :to='`/organization/${$route.params.organizationId}/environment/${$route.params.environmentId}/client/${ctr.clientId}/`'
                                    >
                                        <!-- Only display "view client" link if CTR is done and client still belongs to the current fiduciary (client could be transferred to another fiduciary) -->
                                        <span class='client-link'>{{ $t('btn-view-client') }}</span>
                                    </router-link>
                                </div>
                            </ListItem>
                        </ListRow>
                    </template>
                </List>
                <div class='flex justify-center'>
                    <FriendlyButton
                        :label='`btn-loadmore`'
                        :action='loadMore'
                        id='btn-loadmore'
                        extra-small
                        square
                        :disabled='!nextPageCursor'
                    />
                </div>
            </div>
        </content-box>
    </div>
</template>
