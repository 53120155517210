<script setup>
import { ref, computed, getCurrentInstance, watch } from 'vue';
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';

const instance = getCurrentInstance();

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        default: () => '',
    },
    label: {
        type: String,
    },
    placeholder: {
        type: String,
        default: '',
    },
    narrow: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: String,
        default: () => '',
    },
    mode: {
        type: String,
        default: 'eager',
        validator: function (value) {
            // allowed modes
            const allowedValues = ['lazy', 'eager', 'aggressive'];
            return allowedValues.includes(value);
        },
    },
    info: String,
    edit: {
        type: Boolean,
        default: false,
    },
    formatter: {
        type: Function,
        default: v => v,
    },
    displayFormatter: {
        type: Function,
        default: v => v,
    },
    formatOnType: {
        type: Boolean,
        default: false,
    },
});

// Create a computed property that returns the rules from props to be reactive
const computedRules = computed(() => props.rules);

const inputRef = ref(null);

let { value: inputValue, handleChange, errorMessage } = useField(
    props.name,
    computedRules,
    {
        validateOnValueUpdate: false,
        initialValue: props.value,
        label: props.label,
    });

const emits = defineEmits(['input']);

function onInput (e) {
    emits('input', e.target.value);

    if (
        props.mode === 'aggressive' ||
        (
            props.mode === 'eager' &&
            errorMessage.value
        )
    ) {
        formatAndHandleChange(e.target.value);
    } else {
        if (props.formatOnType) {
            formatAndHandleChange(e.target.value, false);
        } else {
            handleChange(e.target.value, false);
        }
    }
}

function onBlur (e) {
    if (props.mode === 'eager') {
        formatAndHandleChange(e.target.value);
    }
}

function formatAndHandleChange (value, doValidate = true) {
    const formattedValue = props.formatter(value);
    handleChange(formattedValue, doValidate);
    // necessary to avoid reactivity issues on input
    if (formattedValue !== value) instance?.proxy?.$forceUpdate();
}

const required = computed(() => {
    return props.rules.includes('required');
});

const displayedValue = computed(() => {
    return props.displayFormatter(inputValue.value);
});

watch(
    () => props.value,
    (value) => {
        formatAndHandleChange(value, true);
    },
);
</script>

<template>
    <div class='form-input-wrapper' :class='{ narrow }'>
        <label v-if='label' for='name' class='flex'>
            <div class='truncate w-100'>
                {{ label }}<span v-if='required && edit' class='required'>*</span>
            </div>
            <span v-if='info' class='ml-2 font-normal'>
                <Tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-html='info'></div>
                    </template>
                </Tooltip>
            </span>
        </label>
        <div class='form-input' :class='{ "has-error": !!errorMessage }' v-if='edit'>
            <input
                :name='name'
                :id='name'
                :type='type === "password" && showPassword ? "text": type'
                :value='displayedValue'
                :placeholder='placeholder'
                ref='inputRef'
                @input='onInput'
                @blur='onBlur'
            >
        </div>
        <div v-else>
            <slot name='readOnlyContent'>
                {{ displayedValue }}
            </slot>
        </div>
        <p class='form-input-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped lang='postcss'>
.form-input-wrapper {
    display: inline-block;
    width: 100%;
}

.form-input-wrapper {
    &.narrow {
        width: 320px;
    }
}

label {
    @apply text-grey-700 m-0 mb-2;
    font-weight: 600;
    transition: color linear 100ms;
}
.form-input-errors {
    @apply text-red-300 ml-0 inline-block font-bold text-sm transition-all mt-1 mb-0 mt-2;
}
.form-input {
    @apply border-solid border-grey-250 border-2 rounded h-12;
    transition: border-color linear 100ms;
    display: flex;
    position: relative;
}

.form-input:focus-within {
    @apply border-blue-300;
}

.form-input input {
    width: 100%;
}

.form-input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @apply text-grey-300;
    opacity: 1; /* Firefox */
}

.form-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @apply text-grey-300;
}

.form-input input::-ms-input-placeholder { /* Microsoft Edge */
    @apply text-grey-300;
}

.form-input-format {
    position: absolute;
    display: flex;
    align-items: center;
}

.form-input input,
.form-input-format {
    @apply text-grey-700;
    padding: 0;
    margin: 0;
    padding-left: 13px;
    border: none;
    outline: none;
    width: calc(100% - 26px);
    font-family: 'Roboto', sans-serif;
    white-space: pre;
    line-height: 22px;
    background: transparent;
    transition: color linear 100ms;
}
.form-input-format {
    color: #CCC;
    z-index: -1;
    top: 8px;
}
.validated .form-input input {
    color: #3dcc64;
}
.validated label {
    @apply text-red-300;
}
.validated .form-input {
    @apply border-green-300;
}
.has-error label {
    @apply text-red-300;
}
.has-error .form-input {
    @apply border-red-300;
}
.has-error .form-input input {
    @apply text-red-300;
}

.required {
    @apply text-blue-300;
}
</style>
